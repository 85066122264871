import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  Grid,
  Paper,
  Select,
  MenuItem,
  Divider,
} from "@mui/material";
import {
  ArrowUpward,
  People,
  Bookmark,
  ThumbUp,
  Comment,
  Link,
} from "@mui/icons-material";
import { Line, Doughnut } from "react-chartjs-2";
import "chart.js/auto";
import { styled } from "@mui/system";
import { useAuth } from "../../contexts/AuthContext";
import axios from "axios";
import "../Scheduler/scheduler.scss"; // Import the CSS for the loader
import { useNavigate } from "react-router-dom";

const backendUrl = "https://socialoha-server-0b454a3e2f86.herokuapp.com";

const Root = styled("div")({
  margin: 0,
  padding: 0,
  position: "absolute",
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
});

const CustomContainer = styled(Container)({
  padding: "0 !important",
  marginLeft: "40px",
  maxWidth: "100%",
  width: "100%",
});

const CustomPaper = styled(Paper)({
  textAlign: "center",
  padding: "8px", // Reduced padding
  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
  borderRadius: "10px",
});

const CustomSelect = styled(Select)({
  margin: "0 0 10px 0", // Reduced margin
});

// Infinity Loader Component
const InfinityLoader = () => (
  <div className="infinity-loader">
    <div className="infinity"></div>
  </div>
);

const Dashboard = () => {
  const navigate = useNavigate();
  const [timeframe, setTimeframe] = useState("this_week");
  const [data, setData] = useState(null);
  const [reachData, setReachData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [reachLoading, setReachLoading] = useState(false);
  const [userId, setUserId] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState("July");

  const { currentUser } = useAuth();

  const handleTimeframeChange = (event) => {
    setTimeframe(event.target.value);
    setLoading(true);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
    fetchReachData(event.target.value);
  };

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser && currentUser.email) {
        try {
          const userResponse = await axios.get(
            `${backendUrl}/queries/data_user`,
          );
          const users = userResponse.data;
          const user = users.find((item) => item.email === currentUser.email);

          if (user) {
            setUserId(user.user_id);
          } else {
            console.error("User not found");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    const fetchData = async () => {
      try {
        const response = await fetch(
          `${backendUrl}/api/dashboard/${userId}?timeframe=${timeframe}`,
        );
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData().then(() => {
      if (userId) {
        fetchData();
        fetchReachData(selectedMonth);
      }
    });
  }, [currentUser, userId, timeframe, selectedMonth]);

  const fetchReachData = async (month) => {
    setReachLoading(true);
    try {
      const response = await axios.get(
        `${backendUrl}/api/reachdata/${userId}`,
        {
          params: {
            month: month,
          },
        },
      );
      setReachData(response.data);
    } catch (error) {
      console.error("Error fetching reach data:", error);
    } finally {
      setReachLoading(false);
    }
  };

  if (loading) {
    return (
      <Root>
        <CustomContainer>
          <Box my={4} textAlign="center">
            <InfinityLoader />
          </Box>
        </CustomContainer>
      </Root>
    );
  }

  if (!data) {
    return (
      <Root>
        <CustomContainer>
          <Box my={4} textAlign="center">
            <Typography variant="h6" sx={{ fontSize: "14px" }}>
              No data available
            </Typography>
          </Box>
        </CustomContainer>
      </Root>
    );
  }

  return (
    <Root>
      <CustomContainer>
        <Box my={4} display="flex" alignItems="center">
          <Typography variant="h4" component="h1" gutterBottom sx={{ fontSize: "24px" }}>
            Dashboard
          </Typography>
          <CustomSelect
            value={timeframe}
            onChange={handleTimeframeChange}
            displayEmpty
            sx={{ marginLeft: "8px", fontSize: "12px", minWidth: "80px" }}
          >
            <MenuItem value="this_week">This Week</MenuItem>
            <MenuItem value="last_14_days">Last 14 Days</MenuItem>
            <MenuItem value="last_30_days">Last 30 Days</MenuItem>
          </CustomSelect>
        </Box>
        <Grid container spacing={2} alignItems="flex-start"> {/* Reduced spacing */}
          <Grid container item xs={12} md={8} spacing={2}> {/* Reduced spacing */}
            <Grid item xs={12} sm={6} md={4}>
              <CustomPaper>
                <People />
                <Typography variant="h6" sx={{ fontSize: "14px" }}>Total Followers</Typography>
                <Typography variant="h4" sx={{ fontSize: "20px" }}>
                  {data?.totalFollowers ?? undefined}
                </Typography>
              </CustomPaper>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CustomPaper>
                <People />
                <Typography variant="h6" sx={{ fontSize: "14px" }}>Profile Views</Typography>
                <Typography variant="h4" sx={{ fontSize: "20px" }}>
                  {data?.profileViews ?? undefined}
                </Typography>
              </CustomPaper>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CustomPaper>
                <Link />
                <Typography variant="h6" sx={{ fontSize: "14px" }}>Link in Bio Clicks</Typography>
                <Typography variant="h4" sx={{ fontSize: "20px" }}>
                  {data?.linkClicks ?? undefined}
                </Typography>
              </CustomPaper>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CustomPaper>
                <Bookmark />
                <Typography variant="h6" sx={{ fontSize: "14px" }}>Total Saves</Typography>
                <Typography variant="h4" sx={{ fontSize: "20px" }}>
                  {data?.totalSaves ?? undefined}
                </Typography>
              </CustomPaper>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CustomPaper>
                <ThumbUp />
                <Typography variant="h6" sx={{ fontSize: "14px" }}>Total Likes</Typography>
                <Typography variant="h4" sx={{ fontSize: "20px" }}>
                  {data?.totalLikes ?? undefined}
                </Typography>
              </CustomPaper>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CustomPaper>
                <Comment />
                <Typography variant="h6" sx={{ fontSize: "14px" }}>Total Comments</Typography>
                <Typography variant="h4" sx={{ fontSize: "20px" }}>
                  {data?.totalComments ?? undefined}
                </Typography>
              </CustomPaper>
            </Grid>
            <Grid item xs={12}>
  <CustomPaper sx={{ height: "540px" }}>
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="h6" sx={{ fontSize: "14px" }}>Reach</Typography>
      <Select
        value={selectedMonth}
        onChange={handleMonthChange}
        variant="outlined"
        size="small"
        style={{ minWidth: 80, fontSize: "12px" }}
      >
        {months.map((month) => (
          <MenuItem key={month} value={month}>
            {month}
          </MenuItem>
        ))}
      </Select>
    </Box>

    {reachLoading ? (
      <Box textAlign="center" pt={10}>
        <InfinityLoader />
      </Box>
    ) : (
      <Line
        data={{
          labels: reachData?.dates ?? [],
          datasets: [
            {
              label: "Reach",
              data: reachData?.reach ?? [],
              fill: true,
              backgroundColor: "rgba(66, 135, 245, 0.2)",
              borderColor: "#4287f5",
            },
            {
              label: "Likes",
              data: reachData?.likes ?? [],
              fill: true,
              backgroundColor: "rgba(142, 245, 66, 0.2)",
              borderColor: "#8ef542",
            },
          ],
        }}
        options={{
          responsive: true,
          maintainAspectRatio: false,  // Ensure aspect ratio is maintained
          layout: {
            padding: {
              top: 10,
              bottom: 30, // Increase padding at the bottom to fit labels
              left: 10,
              right: 10,
            },
          },
          scales: {
            x: {
              type: "category",
              title: {
                display: true,
                text: "Date",
              },
              ticks: {
                maxRotation: 0,  // Prevent label rotation
                autoSkip: true,   // Automatically skip labels
                padding: 10,      // Add padding to x-axis labels
                font: {
                  size: 10,
                },
              },
              grid: {
                display: false,   // Hide grid lines
              },
            },
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: "Values",
              },
              ticks: {
                padding: 10,      // Add padding to y-axis labels
                font: {
                  size: 10,
                },
              },
            },
          },
          plugins: {
            legend: {
              display: true,
              position: "bottom",
              labels: {
                usePointStyle: true,
                padding: 10,  // Adjust padding for legend
                font: {
                  size: 10,
                },
              },
            },
          },
        }}
      />
    )}
  </CustomPaper>
</Grid>

          </Grid>
          <Grid container item xs={12} md={4} direction="column" spacing={2}>
            <Grid item>
              <CustomPaper sx={{ height: "350px" }}>  {/* Reduced height */}
                <Typography variant="h6" sx={{ fontSize: "14px" }}>Follower Distribution</Typography>
                <Doughnut
                  data={{
                    labels: ["M", "F", "U"],
                    datasets: [
                      {
                        data: data?.genderDistribution?.data ?? [],
                        backgroundColor: ["#4287f5", "#FFB6C1", "#c4c4c4"],
                        hoverBackgroundColor: ["#4287f5", "#FFB6C1", "#c4c4c4"],
                      },
                    ],
                  }}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    cutout: "70%",
                    aspectRatio: 2,  // Adjust aspect ratio to make the doughnut smaller
                    layout: {
                      padding: {
                        top: 5,  // Reduced padding
                        bottom: 5,
                      },
                    },
                    plugins: {
                      legend: {
                        display: true,
                        position: "bottom",
                        labels: {
                          usePointStyle: true,
                          boxWidth: 10,
                          padding: 10,
                          font: {
                            size: 10, // Smaller font size
                          },
                        },
                      },
                    },
                  }}
                />
              </CustomPaper>
            </Grid>
            <Grid item>
              <CustomPaper sx={{ height: "380px", maxWidth: "100%" }}> {/* Add maxWidth */}
                <Typography variant="h6" style={{ fontSize: "14px" }}>Follower Locations</Typography>
                <Doughnut
                  data={{
                    labels: data?.locationDistribution?.labels ?? undefined,
                    datasets: [
                      {
                        data: data?.locationDistribution?.data ?? undefined,
                        backgroundColor: ["#66b3ff", "#99ff99", "#ffcc99", "#ff6666", "#c4c4c4"],
                        hoverBackgroundColor: ["#66b3ff", "#99ff99", "#ffcc99", "#ff6666", "#c4c4c4"],
                      },
                    ],
                  }}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    cutout: "70%",
                    aspectRatio: 2,  // Adjust aspect ratio to make the doughnut smaller
                    layout: {
                      padding: {
                        top: 5,
                        bottom: 5,
                        right: 5,
                        left: 5, // Add padding
                      },
                    },
                    plugins: {
                      legend: {
                        display: true,
                        position: "right", // Adjust position as needed
                        labels: {
                          usePointStyle: true,
                          boxWidth: 10,
                          padding: 10,
                          font: {
                            size: 10, // Smaller font size
                          },
                        },
                      },
                    },
                  }}
                />
              </CustomPaper>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <Box my={2}>
          <button onClick={() => navigate("/analytics")}>
            Go to Analytics
          </button>
        </Box>
      </CustomContainer>
    </Root>
  );
};

export default Dashboard;
