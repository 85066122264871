import React, { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import axios from "axios";
import { Document, Packer, Paragraph, TextRun } from "docx";
import { saveAs } from "file-saver";
import { Stepper, Step, StepLabel } from "@mui/material";
import Ideate from "./ideate"; // Replace StepOne with Ideate
import StepTwo from "./step-two";
import "./ideate.scss";

const backendUrl = "https://socialoha-server-0b454a3e2f86.herokuapp.com";

export default function StepperIdeate() {
  const { currentUser } = useAuth();
  const [formData, setFormData] = useState(() => {
    const savedFormData = localStorage.getItem("formData");
    return savedFormData
      ? JSON.parse(savedFormData)
      : {
          toggleActive: true,
          activeButton: "",
          purpose: "",
          audience: "",
          product: "",
          music: "",
          duration: "",
          textAreaInput: "",
          productOptions: [],
          clips: [],
          business_name: "",
          industry: "",
          list_products: "",
          usp: "",
          business_goal: "",
          taboo: "",
          engagement_question: "",
          best_type_content: "",
          post_caption: "",
          more: "",
        };
  });

  const [activeStep, setActiveStep] = useState(() => {
    const savedStep = localStorage.getItem("activeStep");
    return savedStep ? parseInt(savedStep, 10) : 0;
  });

  const [loading, setLoading] = useState(false); // Loading state management

  const steps = ["Explore content ideas", "Visualize your reel script"];

  useEffect(() => {
    if (currentUser && currentUser.email) {
      const fetchUserData = async () => {
        console.log("currentUser: ", currentUser.email);

        try {
          const userResponse = await axios.get(
            `${backendUrl}/queries/data_user`,
          );
          const users = userResponse.data;
          const user = users.find((item) => item.email === currentUser.email);

          if (user) {
            const userId = user.user_id;

            const businessResponse = await axios.get(
              `${backendUrl}/queries/data_business_info`,
            );
            const businessData = businessResponse.data;

            const userData = businessData.find(
              (item) => item.user_id === userId,
            );

            if (userData) {
              const productOptions = userData.list_products.split(",");

              setFormData((prev) => ({
                ...prev,
                productOptions,
                audience: userData.target_audience || "",
                business_name: userData.business_name || "",
                industry: userData.industry || "",
                list_products: userData.list_products || "",
                usp: userData.usp || "",
                business_goal: userData.business_goal || "",
                taboo: userData.taboo || "",
                engagement_question: userData.engagement_question || "",
                best_type_content: userData.best_type_content || "",
                post_caption: userData.post_caption || "",
              }));
            } else {
              console.log(
                "No business information found for the provided user ID.",
              );
            }
          } else {
            console.log("No user found with the provided email.");
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchUserData();
    }
  }, [currentUser]);

  useEffect(() => {
    localStorage.setItem("formData", JSON.stringify(formData));
  }, [formData]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const generateScript = async () => {
    setLoading(true); // Start loading
    try {
      console.log("formData: ", formData);
      const response = await axios.post(
        `${backendUrl}/reel_generation_ideate`,
        formData,
      );
      const receivedData = response.data.choices[0].message.content;
      console.log(receivedData);

      const cleanedData = receivedData.replace(/```json|```/g, "").trim();

      let clipsArray;
      try {
        clipsArray = JSON.parse(cleanedData);
      } catch (jsonError) {
        console.error("Error parsing cleaned data as JSON:", jsonError);
        setLoading(false); // Stop loading in case of error
        return;
      }

      setFormData((prev) => ({ ...prev, clips: clipsArray.clips }));
      localStorage.setItem("clips", JSON.stringify(clipsArray));
      console.log("Generated Clips Array:", clipsArray);

      setLoading(false); // Stop loading
      setActiveStep(1); // Move to StepTwo
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false); // Stop loading in case of error
    }
  };

  const handleNextStep = async () => {
    if (activeStep === 0) {
      await generateScript(); // Ensure script is generated before moving to next step
    }
  };

  const handlePreviousStep = () => {
    setActiveStep((prev) => Math.max(prev - 1, 0));
    localStorage.setItem("activeStep", activeStep - 1);
    setFormData((prev) => ({
      ...prev,
      clips: [],
    }));
  };

  const handleReset = () => {
    setActiveStep(0);
    localStorage.setItem("activeStep", 0);
    setFormData((prev) => ({
      ...prev,
      purpose: "",
      product: "",
      duration: "",
      textAreaInput: "",
      clips: [],
    }));
  };

  const exportScript = () => {
    const fontFamily = "Georgia";

    const title = new Paragraph({
      children: [
        new TextRun({
          text: `${formData.business_name} Script`,
          bold: true,
          size: 36, // 18pt
          font: fontFamily,
        }),
      ],
      spacing: {
        after: 600, // 60pt space after title
      },
    });

    const paragraphs = formData.clips
      .map((clip, index) => [
        new Paragraph({
          children: [
            new TextRun({
              text: `Clip ${index + 1}`,
              bold: true,
              size: 28, // 14pt
              font: fontFamily,
            }),
          ],
          spacing: {
            after: 300, // 30pt space after Clip title
          },
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: `Start Time: `,
              bold: true,
              size: 24, // 12pt
              font: fontFamily,
            }),
            new TextRun({
              text: `${clip.startTime}`,
              size: 24, // 12pt
            }),
          ],
          spacing: {
            after: 150, // 15pt space after Start Time
          },
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: "Description:\n\n",
              bold: true,
              size: 24, // 12pt
              font: fontFamily,
            }),
          ],
          spacing: {
            after: 0, // 15pt space after Description label
          },
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: `${clip.description}`,
              size: 24, // 12pt
            }),
          ],
          spacing: {
            after: 150, // 10pt space after Description text
          },
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: "Text Overlay:\n",
              bold: true,
              size: 24, // 12pt
              font: fontFamily,
            }),
          ],
          spacing: {
            after: 0, // 60pt space after Text Overlay label
          },
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: `${clip.textOverlay}`,
              size: 24, // 12pt
            }),
          ],
          spacing: {
            after: 600, // 60pt space after Text Overlay text
          },
        }),
      ])
      .flat();

    const doc = new Document({
      sections: [
        {
          children: [title, ...paragraphs],
        },
      ],
    });

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, `${formData.business_name} Script.docx`);
    });

    handleReset();
  };

  const InfinityLoader = () => (
    <div className="infinity-loader">
      <div className="infinity"></div>
    </div>
  );

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <Ideate formData={formData} setFormData={setFormData} />;
      case 1:
        return <StepTwo formData={formData} />;
      default:
        return null;
    }
  };

  return (
    <div className="font-dm-sans">
      <div>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel
                className="pt-4 -mb-4"
                StepIconProps={{
                  style: {
                    color: "#4AABBA",
                  },
                }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>

      {loading ? <InfinityLoader /> : getStepContent(activeStep)}

      <div className="fixed bottom-5 left-[15%] right-0 flex justify-between px-8 py-4">
        <button
          onClick={handlePreviousStep}
          className={`px-4 py-2 rounded ${activeStep === 0 ? "bg-gray-400 text-gray-700 cursor-not-allowed" : "bg-teal text-white hover:bg-light-teal transition-colors duration-500 ease-in-out"}`}
          disabled={activeStep === 0}
        >
          Previous
        </button>

        <div className="flex space-x-4">
          {activeStep === 1 && !loading ? (
            <button
              onClick={exportScript}
              className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-500 transition-colors duration-500 ease-in-out"
            >
              Export
            </button>
          ) : (
            <button
              onClick={handleNextStep}
              className={
                "px-4 py-2 rounded bg-teal text-white hover:bg-light-teal transition-colors duration-500 ease-in-out"
              }
            >
              Next
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
