import { db, auth } from "./firebase-config";
import { doc, setDoc } from "firebase/firestore";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  deleteUser,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
} from "firebase/auth";

/**
 * Logs in a user using their email and password.
 * @returns {Promise<Object>} - The logged-in user's information.
 */
export const loginGoogleUser = async () => {
  try {
    const provider = new GoogleAuthProvider();
    const userCredential = await signInWithPopup(auth, provider);

    console.log("User logged in with ID: ", userCredential.user.uid);
    return userCredential.user;
  } catch (error) {
    console.error("Error logging in user: ", error);
    throw new Error("Error logging in user: " + error.message);
  }
};

/**
 * Creates a new authenticated user and adds their details to Firestore.
 * @param {Object} userData - The user data to add, including email and password.
 */
export const createGoogleUser = async () => {
  let user;

  try {
    // Step 1: Create the user in Firebase Authentication
    const provider = new GoogleAuthProvider();
    const userCredential = await signInWithPopup(auth, provider);
    user = userCredential.user;

    const name = "Google: " + user.displayName;
    const email = user.email;

    // Step 2: Add user details to Firestore
    const userRef = doc(db, "users", user.uid);
    await setDoc(userRef, {
      name,
      email,
    });

    // Step 3: Call your Flask backend to create the user in MySQL
    const response = await fetch(
      "https://socialoha-server-0b454a3e2f86.herokuapp.com/queries/create_user",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, email }),
      },
    );

    if (!response.ok) {
      throw new Error("Failed to create user in MySQL");
    }

    const result = await response.json();
    console.log("User created in MySQL:", result);

    return user;
  } catch (error) {
    // If an error occurs during any step, handle and log the error
    console.error("Error creating user:", error);
    // Rollback Firebase user creation (optional based on application logic)
    if (user && user.uid) {
      try {
        await deleteUser(auth.currentUser); // Delete Firebase user
        console.log("Firebase user deleted due to error");
      } catch (deleteError) {
        console.error("Error deleting Firebase user:", deleteError.message);
      }
    }
    throw new Error("Error creating user: " + error.message);
  }
};

/**
 * Logs in a user using their email and password.
 * @param {string} email - The user's email.
 * @param {string} password - The user's password.
 * @returns {Promise<Object>} - The logged-in user's information.
 */
export const loginUser = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password,
    );
    return userCredential.user; // Return the authenticated user object
  } catch (error) {
    throw new Error("Error logging in user: " + error.message);
  }
};

/**
 * Creates a new authenticated user and adds their details to Firestore.
 * @param {Object} userData - The user data to add, including email and password.
 */
export const createUser = async ({
  name,
  email,
  password,
  ...additionalData
}) => {
  let userCredential, user;

  try {
    // Step 1: Create the user in Firebase Authentication
    userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password,
    );
    user = userCredential.user;

    // Step 2: Add user details to Firestore
    const userRef = doc(db, "users", user.uid);
    await setDoc(userRef, {
      name,
      email,
      ...additionalData,
    });

    // Step 3: Call your Flask backend to create the user in MySQL
    const response = await fetch(
      "https://socialoha-server-0b454a3e2f86.herokuapp.com/queries/create_user",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, email }),
      },
    );

    if (!response.ok) {
      throw new Error("Failed to create user in MySQL");
    }

    const result = await response.json();
    console.log("User created in MySQL:", result);

    return user; // Return the authenticated user object
  } catch (error) {
    // If an error occurs during any step, handle and log the error
    // Rollback Firebase user creation (optional based on application logic)
    if (user && user.uid) {
      try {
        await deleteUser(auth.currentUser); // Delete Firebase user
      } catch (deleteError) {
        console.error("Error deleting Firebase user:", deleteError.message);
      }
    }
    throw new Error("Error creating user: " + error.message);
  }
};

export const logOut = () => {
  signOut(auth);
};
