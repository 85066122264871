import React from "react";
import { Link } from "react-router-dom";
import { logOut } from "../../services/firebaseService";
import { FaBookmark } from 'react-icons/fa'; // Import a bookmark icon

export default function Navbar() {
  return (
    <div className="bg-[#f7f6f5] flex flex-col items-start h-screen fixed shadow-md w-[12%]">
      <nav className="w-full h-full">
        <div className="flex flex-col h-full mt-24">
          {/* <Link
            to="/analytics"
            className="text-[#333] text-lg flex items-center p-2.5 rounded-lg mb-[3vh] -ml-3 hover:bg-[#6cc8d3] hover:text-white transition-colors duration-500 ease-in-out"
          >
            <i className="fas fa-tachometer-alt mr-2"></i>
            Analytics
          </Link> */}
          {/* <Link
            to="/ideate"
            className="text-[#333] text-lg flex items-center p-2.5 rounded-lg mb-[3vh] -ml-2 hover:bg-[#6cc8d3] hover:text-white transition-colors duration-500 ease-in-out"
          >
            <i className="fas fa-th mr-2"></i>
            Ideate
          </Link> */}
          <Link
            to="/scheduler/calendar"
            className="text-[#333] text-lg flex items-center p-2.5 rounded-lg mb-[3vh] -ml-2 hover:bg-[#6cc8d3] hover:text-white transition-colors duration-500 ease-in-out"
          >
            <i className="fas fa-calendar-alt mr-2"></i>
            Scheduler
          </Link>
          {/* <Link
            to="/dashboard"
            className="text-[#333] text-lg flex items-center p-2.5 rounded-lg mb-[3vh] -ml-2 hover:bg-[#6cc8d3] hover:text-white transition-colors duration-500 ease-in-out"
          >
            <i className="fas fa-chart-bar mr-2"></i>
            Dashboard
          </Link> */}
          <Link
            to="/aicontent"
            className="text-[#333] text-lg flex items-center p-2.5 rounded-lg mb-[3vh] -ml-3 hover:bg-[#6cc8d3] hover:text-white transition-colors duration-500 ease-in-out"
          >
            <i className="fas fa-cogs mr-2"></i>
            AI Content
          </Link>
          <Link
            to="/scheduler/writepost"
            className="text-[#333] text-lg flex items-center p-2.5 rounded-lg mb-[3vh] -ml-3 hover:bg-[#6cc8d3] hover:text-white transition-colors duration-500 ease-in-out"
          >
            <i className="fas fa-pen mr-2"></i>
            Write a Post
          </Link>
          <Link
            to="/saved_reels" // Link to the saved reels page
            className="text-[#333] text-lg flex items-center p-2.5 rounded-lg mb-[3vh] -ml-3 hover:bg-[#6cc8d3] hover:text-white transition-colors duration-500 ease-in-out"
          >
            <FaBookmark className="mr-2" /> {/* Bookmark icon */}
            Saved Reels
          </Link>
          <Link
            to="/signup"
            className="text-[#333] text-lg flex items-center p-2.5 rounded-lg mb-[3vh] -ml-3 hover:bg-[#6cc8d3] hover:text-white transition-colors duration-500 ease-in-out"
          >
            <button onClick={logOut}>
              <i className="fas fa-sign-out-alt mr-2"></i>
              Logout
            </button>
          </Link>
        </div>
      </nav>
    </div>
  );
}
