import React, { useState, useEffect } from "react";
import { DeviceFrameset } from "react-device-frameset";
import "react-device-frameset/styles/marvel-devices.min.css";
import "../Scheduler/scheduler.scss"; // Import combined styles
import defaultImage from "../../assets/placeholder_image.png"; // Replace with your default image
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { useAuth } from "../../contexts/AuthContext"; // Adjust the path as needed
import { useNavigate } from "react-router-dom"; // Import useNavigate
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal"; // You'll need to install this
import { useCallback } from "react"; // Import useCallback

const backendUrl = "https://socialoha-server-0b454a3e2f86.herokuapp.com";
// const backendUrl = 'http://localhost:8080'
const InfinityLoader = () => (
  <div className="infinity-loader">
    <div className="infinity"></div>
  </div>
);

const WritePost = () => {
  const [isCampaignModalOpen, setIsCampaignModalOpen] = useState(false); // Updated Modal state for Campaign
  const [campaignName, setCampaignName] = useState("");
  const [campaignDescription, setCampaignDescription] = useState("");
  const [caption, setCaption] = useState("");
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState("");
  const [hashtags, setHashtags] = useState("#SociAloha");
  const [location, setLocation] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());
  const [contentType, setContentType] = useState("");
  const [campaigns, setCampaigns] = useState([]);
  const [campaignId, setCampaignId] = useState("");
  const [tags, setTags] = useState("");
  const [userId, setUserId] = useState(null);
  const [profilePicture, setProfilePicture] = useState("");
  const [loading, setLoading] = useState(false); // State for loading
  const [errors, setErrors] = useState({}); // State for error messages

  const { currentUser } = useAuth();
  const navigate = useNavigate(); // Initialize useNavigate

  // Function to open the campaign modal
  const openCampaignModal = () => {
    setIsCampaignModalOpen(true);
  };

  // Function to close the campaign modal
  const closeCampaignModal = () => {
    setIsCampaignModalOpen(false);
  };

  const fetchCampaigns = useCallback(async () => {
    if (userId) {
      try {
        // Pass userId directly in the URL
        const campaignResponse = await axios.get(
          `${backendUrl}/queries/data_campaigns/${userId}`,
        );
        setCampaigns(campaignResponse.data);
      } catch (error) {
        console.error("Error fetching campaigns:", error);
      }
    }
  }, [userId]);

  useEffect(() => {
    Modal.setAppElement("#root");

    const fetchUserData = async () => {
      if (currentUser && currentUser.email) {
        try {
          const userResponse = await axios.get(
            `${backendUrl}/queries/data_user`,
          );
          const users = userResponse.data;
          const user = users.find((item) => item.email === currentUser.email);

          if (user) {
            setUserId(user.user_id); // Assuming user_id is the correct field name
          } else {
            console.error("User not found");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    const fetchProfilePicture = async (userId) => {
      try {
        const response = await axios.get(
          `${backendUrl}/api/profile_picture/${userId}`,
        );
        setProfilePicture(response.data.profile_picture_url);
      } catch (error) {
        console.error("Error fetching profile picture:", error);
      }
    };

    fetchUserData().then(() => {
      if (userId) {
        fetchProfilePicture(userId);
        fetchCampaigns(); // Call fetchCampaigns here
      }
    });
  }, [currentUser, userId, fileUrl, fetchCampaigns]);

  const handleCreateCampaign = async () => {
    if (!campaignName || !campaignDescription) {
      alert("Both Campaign Name and Description are required.");
      return;
    }

    try {
      const response = await axios.post(
        `${backendUrl}/queries/create_campaign`,
        {
          name: campaignName,
          description: campaignDescription,
          user_id: userId, // Pass the userId as part of the body
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      fetchCampaigns();
      closeCampaignModal();
    } catch (error) {
      console.error("Error creating campaign:", error);
      alert("Error creating campaign. Please try again later.");
    }
  };

  const validateFields = () => {
    const newErrors = {};
    if (!file) {
      newErrors.file = "Picture or video is required.";
      alert(newErrors.file); // Show alert for missing picture
    }
    if (!caption) newErrors.caption = "Caption is required.";
    if (!contentType) newErrors.contentType = "Content type is required.";
    if (!campaignId) newErrors.campaignId = "Campaign is required.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if no errors
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);
      setErrors((prevErrors) => ({ ...prevErrors, file: "" })); // Clear the file error if a file is selected
      const reader = new FileReader();
      reader.onloadend = () => {
        setFileUrl(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setFile(null);
      setFileUrl("");
      setErrors((prevErrors) => ({
        ...prevErrors,
        file: "Picture or video is required.",
      })); // Set error if no file is selected
    }
  };

  const uploadImageToS3 = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(`${backendUrl}/api/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data.imageUrl; // Return the URL of the uploaded image
    } catch (error) {
      console.error("Error uploading image to S3:", error);
      return null;
    }
  };

  const handleSchedule = async () => {
    if (!validateFields()) return;

    setLoading(true); // Show loading
    const fileUrl = await uploadImageToS3(file);
    if (!fileUrl) {
      setErrors({ file: `Failed to upload ${contentType.toLowerCase()}.` });
      setLoading(false); // Hide loading
      return;
    }
    let encodedUserTags = null;

    if (tags && tags.trim()) {
      const tagArray = tags.split(",").map((tag) => tag.trim());

      // If there's only one tag, set encodedUserTags as the tag itself
      if (tagArray.length === 1) {
        encodedUserTags = tagArray[0];
      } 
      // If more than one tag, process as an array of objects
      else if (tagArray.length > 1) {
        const userTagsArray = tagArray.map((tag) => ({ username: tag }));
        encodedUserTags = JSON.stringify(userTagsArray);
      }
    }

    setTags(encodedUserTags);
    
    const scheduledDateTime = moment
    .tz(
      {
        year: startDate.getFullYear(),
        month: startDate.getMonth(),
        date: startDate.getDate(),
        hour: startTime.getHours(),
        minute: startTime.getMinutes(),
      },
      "America/New_York"
    );

  const nyDateTime = scheduledDateTime.format();  // Full datetime in ISO format
  const nyDate = scheduledDateTime.format("YYYY-MM-DD");  // Extracted date in NY timezone
  const nyTime = scheduledDateTime.format("HH:mm:ss");  // Extracted time in NY timezone

  // Prepare post data with consistent datetime handling
  const postData = {
    user_id: userId,
    file_url: fileUrl,
    tags: encodedUserTags, // Use encoded tags
    date: nyDate,  // Correct date in NY timezone
    time: nyTime,  // Correct time in NY timezone
    campaign_id: campaignId,
    status: "pending",
    scheduled_at: nyDateTime,  // Full ISO datetime in New York timezone
    story_type: "image",  // Example: This can be dynamic if needed
  };

    if (contentType.toLowerCase() === "stories") {
      axios
        .post(`${backendUrl}/queries/schedule_story`, postData)
        .then((response) => {
          if (response.data.error) {
            setErrors({
              apiError: "Error scheduling story: " + response.data.error,
            });
          } else {
            navigate("/scheduler/calendar");
          }
          setLoading(false); // Hide loading
        })
        .catch((error) => {
          console.error("Error:", error);
          setErrors({ apiError: "Error scheduling story" });
          setLoading(false); // Hide loading
        });
    } else {
      postData.caption = caption;
      postData.hashtags = hashtags;
      postData.location_id = location;

      const endpoint =
        contentType.toLowerCase() === "image"
          ? `${backendUrl}/queries/schedule_post`
          : `${backendUrl}/queries/schedule_reel_post`;

      axios
        .post(endpoint, postData)
        .then((response) => {
          if (response.data.error) {
            setErrors({
              apiError: "Error scheduling post: " + response.data.error,
            });
          } else {
            navigate("/scheduler/calendar");
          }
          setLoading(false); // Hide loading
        })
        .catch((error) => {
          console.error("Error:", error);
          setErrors({ apiError: "Error scheduling post" });
          setLoading(false); // Hide loading
        });
    }
  };

  const handlePostNow = async () => {
    if (!validateFields()) return;

    setLoading(true); // Show loading
    const fileUrl = await uploadImageToS3(file);
    if (!fileUrl) {
      setErrors({ file: `Failed to upload ${contentType.toLowerCase()}.` });
      setLoading(false); // Hide loading
      return;
    }

    const userTagsArray = tags
      .split(",")
      .map((tag) => ({ username: tag.trim() }));
    const encodedUserTags = JSON.stringify(userTagsArray);
    setTags(encodedUserTags);

    const postData = {
      file_url: fileUrl,
      caption,
      location_id: location,
      tags,
      content_type: contentType,
      user_id: userId,
    };

    const endpoint =
      contentType.toLowerCase() === "stories"
        ? `/api/publish_image/${userId}`
        : `/api/publish_${contentType.toLowerCase()}/${userId}`;

    axios
      .post(`${backendUrl}${endpoint}`, postData)
      .then((response) => {
        if (response.data.error) {
          setErrors({ apiError: "Error posting: " + response.data.error });
        } else {
          navigate("/scheduler/calendar");
        }
        setLoading(false); // Hide loading
      })
      .catch((error) => {
        console.error("Error:", error);
        setErrors({ apiError: "Error posting" });
        setLoading(false); // Hide loading
      });
  };

  return (
    <div className="write-post-container">
      {loading && <InfinityLoader />}
      {errors.apiError && (
        <div className="error-message">{errors.apiError}</div>
      )}
      <div className="post-preview">
        <DeviceFrameset
          device="iPhone X"
          color="black"
          width="350px"
          height="700px"
        >
          <div className="instagram-post">
            <div className="post-header">
              <img
                src={profilePicture || defaultImage}
                alt="profile"
                className="profile-pic"
              />
              <div className="post-user-info">
                <span className="username">SociAloha</span>
                <span className="location">{location}</span>
              </div>
            </div>
            <div className="post-image">
              <input
                type="file"
                accept="image/*,video/*"
                onChange={handleFileUpload}
                className="image-upload-input"
              />
              {fileUrl &&
              (fileUrl.startsWith("data:video/mp4") ||
                fileUrl.startsWith("data:video/quicktime")) ? (
                <video
                  controls
                  width="100%"
                  height="auto"
                  autoPlay
                  loop
                  style={{ maxWidth: "100%", height: "100%" }}
                >
                  <source src={fileUrl} type="video/mp4" />
                </video>
              ) : (
                <img src={fileUrl || defaultImage} alt="" />
              )}
            </div>
            <div className="post-footer">
              <div className="post-interactions">
                <span>❤️</span>
                <span>💬</span>
                <span>📤</span>
              </div>
              <div className="post-caption">
                <span className="bold-text">SociAloha</span> {caption}
              </div>
              <div className="post-hashtags">
                <span>{hashtags}</span>
              </div>
              <div className="post-comments">
                <span>View all 10 comments</span>
              </div>
            </div>
          </div>
        </DeviceFrameset>
        <div className="button-group">
          <button
            className="btn-schedule"
            style={{ marginRight: "10px", marginLeft: "10px" }}
            onClick={handleSchedule}
          >
            Schedule
          </button>
          <button className="btn-save-draft" onClick={handlePostNow}>
            Post Now
          </button>
        </div>
      </div>

      <div className="post-options">
        <div className="input-group">
          <label>Select Content Type</label>
          <select
            value={contentType}
            onChange={(e) => setContentType(e.target.value)}
          >
            <option value="">Select</option>
            <option value="REELS">REELS</option>
            <option value="IMAGE">IMAGE</option>
            <option value="CAROUSSEL">CAROUSSEL</option>
            <option value="STORIES">STORIES</option>
            <option value="VIDEO">VIDEO</option>
          </select>
          {errors.contentType && (
            <div className="error-message">{errors.contentType}</div>
          )}
        </div>

        <div className="input-group">
          <label>Create/Select Campaign</label>
          <div className="campaign-select">
            <select
              value={campaignId}
              onChange={(e) => {
                setCampaignId(e.target.value);
              }}
            >
              <option value="">Select</option>
              {campaigns.map((campaign) => (
                <option key={campaign.campaign_id} value={campaign.campaign_id}>
                  {" "}
                  {/* Use campaign.campaign_id */}
                  {campaign.name}
                </option>
              ))}
            </select>
            {errors.campaignId && (
              <div className="error-message">{errors.campaignId}</div>
            )}
            {/* Add Button with + icon */}
            <button className="add-campaign-btn" onClick={openCampaignModal}>
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </div>
        </div>

        <div className="input-group date-time-group">
          <div className="date-picker">
            <label>Date</label>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="MMMM d, yyyy"
              className="form-control"
              placeholderText="Select Date"
            />
          </div>
          <div className="time-picker">
            <label>Time</label>
            <DatePicker
              selected={startTime}
              onChange={(time) => setStartTime(time)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="h:mm aa"
              className="form-control"
              placeholderText="Select Time"
            />
          </div>
        </div>

        <div className="input-group">
          <label>Caption</label>
          <input
            type="text"
            value={caption}
            onChange={(e) => setCaption(e.target.value)}
            placeholder="I am proud to say SociAloha is a small-business supporter"
          />
          {errors.caption && (
            <div className="error-message">{errors.caption}</div>
          )}
        </div>

        <div className="input-group">
          <label>Hashtags</label>
          <input
            type="text"
            value={hashtags}
            onChange={(e) => setHashtags(e.target.value)}
          />
        </div>

        <div className="input-group">
          <label>Location</label>
          <input
            type="text"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            placeholder="Search"
          />
        </div>

        <div className="input-group">
          <label>Tags</label>
          <input
            type="text"
            value={tags}
            onChange={(e) => setTags(e.target.value)}
            placeholder="Search"
          />
        </div>
      </div>

      {/* Modal for adding campaign */}
      <Modal
        isOpen={isCampaignModalOpen}
        onRequestClose={closeCampaignModal}
        contentLabel="Create Campaign"
        className="campaign-modal-content"
        overlayClassName="campaign-modal-overlay"
      >
        <h2>Create New Campaign</h2>
        <div>
          <label>Campaign Name</label>
          <input
            type="text"
            value={campaignName}
            onChange={(e) => setCampaignName(e.target.value)}
          />
        </div>
        <div>
          <label>Campaign Description</label>
          <textarea
            value={campaignDescription}
            onChange={(e) => setCampaignDescription(e.target.value)}
          />
        </div>
        <div className="modal-buttons">
          <button onClick={handleCreateCampaign}>Create</button>
          <button onClick={closeCampaignModal}>Cancel</button>
        </div>
      </Modal>
    </div>
  );
};

export default WritePost;
