import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment-timezone";
import Modal from "react-modal"; // Import the modal for adding a campaign
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import "../Scheduler/scheduler";
import { useCallback } from "react"; // Import useCallback

const backendUrl = "https://socialoha-server-0b454a3e2f86.herokuapp.com";
// const backendUrl = 'http://localhost:8080'

const EditPostModal = ({ isOpen, onClose, postDetails, onSave }) => {
  const getDefaultDate = () => (postDetails && postDetails.date)
  const getDefaultTime = () => (postDetails && postDetails.time)
  const [formValues, setFormValues] = useState({
    caption: "",
    date: getDefaultDate(), // Use postDetails date or fallback
    time: getDefaultTime(),  
    hashtags: "",
    location: "",
    tags: "",
    thumbnail: "",
    campaign: "", // Store the campaign id
  });
  const [file, setFile] = useState(null); // Add state for file
  const [fileUrl, setFileUrl] = useState(""); // Add state for file URL
  const [isCampaignModalOpen, setIsCampaignModalOpen] = useState(false); // Modal state
  const [campaignName, setCampaignName] = useState("");
  const [campaignDescription, setCampaignDescription] = useState("");
  const [campaigns, setCampaigns] = useState([]);

  const fetchCampaigns = useCallback(async () => {
    if (postDetails && postDetails.user_id) {  // Check if postDetails and userId exist
      try {
        const campaignResponse = await axios.get(
          `${backendUrl}/queries/data_campaigns/${postDetails.user_id}`
        );
        setCampaigns(campaignResponse.data);
      } catch (error) {
        console.error("Error fetching campaigns:", error);
      }
    }
  }, [postDetails]);

  useEffect( () => {
    if (postDetails) {
      setFormValues({
        caption: postDetails.caption || postDetails.title || "",
        date: postDetails.start ? postDetails.start.toISOString().slice(0, 10) : "",
        time: postDetails.start ? postDetails.start.toTimeString().slice(0, 5) : "",
        hashtags: postDetails.hashtags || "",
        location: postDetails.location || "",
        tags: postDetails.tags || "",
        thumbnail: postDetails.thumbnail || "",
        campaign: postDetails.campaign || "", // Use campaign id for default value
      });
    }
  }, [postDetails,isOpen]);

  useEffect(() => {
    const fetchData = async () => {
      await fetchCampaigns();
    };
  
    fetchData();
  }, [fetchCampaigns]);

  useEffect(() => {
    if (!isOpen) {
      setFileUrl(""); // Reset the fileUrl when the modal is closed
    }
  }, [isOpen]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // If campaign is being changed, store the campaign ID
    if (name === 'campaign') {
      setFormValues({
        ...formValues,
        campaign: value,  // Directly store the campaign ID as it's passed as the value
      });
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setFile(file)
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFileUrl(reader.result); // Prioritize showing this newly uploaded file
      };
      reader.readAsDataURL(file);
    }
  };

  const uploadImageToS3 = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(`${backendUrl}/api/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data.imageUrl; // Return the URL of the uploaded image
    } catch (error) {
      console.error("Error uploading image to S3:", error);
      return null;
    }
  };


  const handleSave = async () => {
    // Upload image to S3 if there's a file
    let uploadedImageUrl = formValues.thumbnail;
    if (file) {
      uploadedImageUrl = await uploadImageToS3(file);
      if (!uploadedImageUrl) {
        console.error("Error uploading image to S3");
        return;
      }
    }

    const scheduledDateTime = moment.tz(`${formValues.date}T${formValues.time}`, "America/New_York");

    // Format the `scheduled_at` to store in the database
    const scheduledAt = scheduledDateTime.format();  // This is in ISO 8601 format, in New York time

    // Ensure the `start` and `end` times are both in New York time
    const startDateTime = scheduledDateTime.toDate();  // Convert to JavaScript Date object
    const start = startDateTime;
    const end = startDateTime;

    // Prepare the updated event object
    const updatedEvent = {
      ...postDetails,
      caption: formValues.caption,
      hashtags: formValues.hashtags,
      location: formValues.location,
      tags: formValues.tags,
      thumbnail: uploadedImageUrl, 
      campaign_id: formValues.campaign,
      campaign: formValues.campaign,
      date: formValues.date,  // Keep the date unchanged
      time: formValues.time,  // Keep the time unchanged
      scheduled_at: scheduledAt,  // Store the full datetime with timezone
      start: start,
      end: end,
    };

    let apiUrl;
    if (postDetails.type === "post") {
      apiUrl = `${backendUrl}/queries/update_post_info/${postDetails.id}`;
    } else if (postDetails.type === "reel") {
      apiUrl = `${backendUrl}/queries/update_reel_info/${postDetails.id}`;
    } else if (postDetails.type === "story") {
      apiUrl = `${backendUrl}/queries/update_story_info/${postDetails.id}`;
    } else {
      console.error("Unknown post type");
      return;
    }

    try {
      const response = await axios.put(apiUrl, updatedEvent);

      if (response.status !== 200) {
        throw new Error(`Failed to update ${postDetails.type}`);
      }

      onSave(updatedEvent); // Call the onSave function to update the state in the parent component
    } catch (error) {
      console.error("Error updating post:", error);
    }
  };
  
  const openCampaignModal = () => {
    setIsCampaignModalOpen(true);
  };

  const closeCampaignModal = async () => {
    await fetchCampaigns();
    setIsCampaignModalOpen(false);
  };

  const handleCreateCampaign = async () => {
    if (!campaignName || !campaignDescription) {
      alert("Both Campaign Name and Description are required.");
      return;
    }

    try {
      const response = await axios.post(
        `${backendUrl}/queries/create_campaign`,
        {
          name: campaignName,
          description: campaignDescription,
          user_id: postDetails.user_id, // Assuming the user_id is part of postDetails
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      closeCampaignModal(); // Close modal after creating campaign
      // Refresh the campaigns after successfully creating the new campaign
      if (response.data && response.data.campaign_id) {
        campaigns.push(response.data); // Add the new campaign to the list
      }
    } catch (error) {
      console.error("Error creating campaign:", error);
      alert("Error creating campaign. Please try again later.");
    }
  };

  if (!isOpen) return null;

  return (
    <>
      <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
        <div className="bg-white w-full max-w-4xl mx-auto p-8 rounded-lg shadow-lg">
          <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold text-gray-800">
          {`Edit ${postDetails.type}`}
        </h2>            <button onClick={onClose} className="text-gray-400 hover:text-gray-600">✖</button>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="col-span-1">
  <div className="border-2 border-dashed border-gray-300 h-72 flex items-center justify-center text-gray-400 rounded-lg">
    <label htmlFor="file-upload" className="cursor-pointer h-full w-full flex items-center justify-center">
      {fileUrl ? (
        fileUrl.startsWith("data:video/mp4") || fileUrl.startsWith("data:video/quicktime") ? (
          <video
            key={fileUrl} // Adding key to force re-render
            width="100%"
            height="auto"
            autoPlay
            loop
            className="h-full object-cover"
          >
            <source src={fileUrl} type="video/mp4" />
          </video>
        ) : (
          <img
            key={fileUrl} // Adding key to force re-render
            src={fileUrl}
            alt="Uploaded File"
            className="h-full object-cover"
          />
        )
      ) : formValues.thumbnail ? (
        formValues.thumbnail.endsWith(".mp4") || formValues.thumbnail.endsWith(".mov")? (
          <video
            key={formValues.thumbnail} // Adding key to force re-render
            autoPlay
            loop
            width="100%"
            height="auto"
            className="h-full object-cover"
          >
            <source src={formValues.thumbnail} type="video/mp4" />
          </video>
        ) : (
          <img
            key={formValues.thumbnail} // Adding key to force re-render
            src={formValues.thumbnail}
            alt="Post Thumbnail"
            className="h-full object-cover"
          />
        )
      ) : (
        <span className="block">No media selected</span>
      )}
      <input
        id="file-upload"
        type="file"
        accept="image/*,video/*"
        className="hidden"
        onChange={handleFileUpload}
      />
    </label>
  </div>
</div>

            <div className="col-span-1 space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Caption</label>
                <input
                  type="text"
                  name="caption"
                  value={formValues.caption}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border border-gray-300 rounded-lg"
                />
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">Date</label>
                  <input
                    type="date"
                    name="date"
                    value={formValues.date}
                    onChange={handleChange}
                    className="w-full mt-1 p-2 border border-gray-300 rounded-lg"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Time</label>
                  <input
                    type="time"
                    name="time"
                    value={formValues.time}
                    onChange={handleChange}
                    className="w-full mt-1 p-2 border border-gray-300 rounded-lg"
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Hashtags</label>
                <input
                  type="text"
                  name="hashtags"
                  value={formValues.hashtags}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border border-gray-300 rounded-lg"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Location</label>
                <input
                  type="text"
                  name="location"
                  value={formValues.location}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border border-gray-300 rounded-lg"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Tags</label>
                <input
                  type="text"
                  name="tags"
                  value={formValues.tags}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border border-gray-300 rounded-lg"
                />
              </div>

              {/* Dropdown for Campaigns */}
              <div>
                <label className="block text-sm font-medium text-gray-700">Campaign</label>
                <div className="campaign-select" style={{ display: 'flex', alignItems: 'center' }}> {/* Apply flexbox here */}
                <select
                  name="campaign"
                  value={formValues.campaign}
                  onChange={handleChange}
                  className="mt-1 p-2 border border-gray-300 rounded-lg"
                  style={{ width: '180px' }} // Set a fixed width for the select element
                >
                    <option value="">Select</option>
                    {campaigns.map((campaign) => (
                      <option key={campaign.campaign_id} value={campaign.campaign_id}>
                        {campaign.name}
                      </option>
                    ))}
                  </select>
                  <button className="add-campaign-btn" onClick={openCampaignModal} style={{ marginLeft: '10px' }}>
                    <FontAwesomeIcon icon={faPlus} />
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Centered Buttons */}
          <div className="mt-6 flex justify-center space-x-4">
            <button
              onClick={onClose}
              className="text-gray-500 border border-gray-300 px-4 py-2 rounded-lg hover:text-gray-700"
            >
              Cancel
            </button>
            <button
              onClick={handleSave}
              className="text-teal-500 border border-teal-300 px-4 py-2 rounded-lg hover:text-teal-700"
            >
              Save
            </button>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isCampaignModalOpen}
        onRequestClose={closeCampaignModal}
        contentLabel="Create Campaign"
        className="campaign-modal-content"
        overlayClassName="campaign-modal-overlay"
      >
        <h2>Create New Campaign</h2>
        <div>
          <label>Campaign Name</label>
          <input
            type="text"
            value={campaignName}
            onChange={(e) => setCampaignName(e.target.value)}
          />
        </div>
        <div>
          <label>Campaign Description</label>
          <textarea
            value={campaignDescription}
            onChange={(e) => setCampaignDescription(e.target.value)}
          />
        </div>
        <div className="modal-buttons">
          <button onClick={handleCreateCampaign}>Create</button>
          <button onClick={closeCampaignModal}>Cancel</button>
        </div>
      </Modal>
    </>
  );
};

export default EditPostModal;