import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Container,
  Typography,
  Card,
  Grid,
  Box,
  Button,
  ButtonGroup,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { DeviceFrameset } from "react-device-frameset";
import "react-device-frameset/styles/marvel-devices.min.css";
import "./dashboard.scss"; // Ensure this SCSS file exists
import defaultImage from "../../assets/placeholder_image.png"; // Replace with your default image
import { useAuth } from "../../contexts/AuthContext"; // Adjust the path as needed

const backendUrl = "https://socialoha-server-0b454a3e2f86.herokuapp.com";
// const backendUrl = 'http://localhost:8080';

const Dashboard = () => {
  const [mediaData, setMediaData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [profilePicture, setProfilePicture] = useState("");
  const [selectedMediaType, setSelectedMediaType] = useState("All");
  const [selectedSort, setSelectedSort] = useState("");
  const [userId, setUserId] = useState(null);

  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser && currentUser.email) {
        try {
          const userResponse = await axios.get(
            `${backendUrl}/queries/data_user`,
          );
          const users = userResponse.data;
          const user = users.find((item) => item.email === currentUser.email);

          if (user) {
            setUserId(user.user_id); // Assuming user_id is the correct field name
          } else {
            console.error("User not found");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    const fetchProfilePicture = async (userId) => {
      try {
        const response = await axios.get(
          `${backendUrl}/api/profile_picture/${userId}`,
        );
        setProfilePicture(response.data.profile_picture_url);
      } catch (error) {
        console.error("Error fetching profile picture:", error);
      }
    };

    const fetchAllMedia = async (userId) => {
      try {
        const response = await axios.get(
          `${backendUrl}/api/all_media/${userId}`,
        );
        const mediaItems = response.data.data;

        const insightsPromises = mediaItems.map((item) =>
          fetchMediaInsights(item, userId),
        );
        const insightsResults = await Promise.all(insightsPromises);

        const combinedData = mediaItems.map((item, index) => ({
          ...item,
          insights: insightsResults[index].data,
        }));

        setMediaData(combinedData);
        filterMediaData(combinedData, selectedMediaType);
      } catch (error) {
        console.error("Error fetching media data:", error);
      }
    };

    const fetchMediaInsights = async (item, userId) => {
      const mediaId = item.id;
      let insightsUrl = "";
      if (item.media_type === "VIDEO") {
        insightsUrl = `${backendUrl}/api/reel_insights/${userId}/${mediaId}`;
      } else if (item.media_type === "IMAGE") {
        insightsUrl = `${backendUrl}/api/image_insights/${userId}/${mediaId}`;
      } else if (item.media_type === "STORY") {
        insightsUrl = `${backendUrl}/api/story_insights/${userId}/${mediaId}`;
      } else {
        return { data: {} }; // Return empty data for unsupported media types
      }

      try {
        const response = await axios.get(insightsUrl);
        return response;
      } catch (error) {
        console.error(`Error fetching insights for ${mediaId}:`, error);
        return { data: {} };
      }
    };

    fetchUserData().then(() => {
      if (userId) {
        fetchProfilePicture(userId);
        fetchAllMedia(userId);
      }
    });
  }, [currentUser, userId, selectedMediaType]);

  const filterMediaData = (data, tab) => {
    if (tab === "All") {
      setFilteredData(data);
    } else if (tab === "Reel") {
      setFilteredData(data.filter((item) => item.media_type === "VIDEO"));
    } else if (tab === "Post") {
      setFilteredData(data.filter((item) => item.media_type === "IMAGE"));
    } else if (tab === "Stories") {
      setFilteredData(data.filter((item) => item.media_type === "STORY"));
    }
  };

  useEffect(() => {
    filterMediaData(mediaData, selectedMediaType);
  }, [selectedMediaType, mediaData]);

  const GeneralStatistics = ({ statistics }) => {
    return (
      <Box className="general-statistics">
        <Typography
          variant="h6"
          className="general-statistics-title"
          gutterBottom
        >
          General Statistics
        </Typography>
        <Grid container spacing={2}>
          {statistics.map((stat) => (
            <Grid item xs={6} sm={4} md={3} key={stat.title}>
              <Card className="general-statistics-card">
                <Typography className="stat-value">{stat.value}</Typography>
                <Typography className="stat-title">{stat.title}</Typography>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };

  const getStatisticsForMedia = (item, insightsArray) => {
    if (item.media_type === "VIDEO") {
      return [
        {
          title: "Accounts Reached",
          value:
            insightsArray.find((insight) => insight.name === "reach")?.values[0]
              .value || 0,
        },
        {
          title: "Total Interactions",
          value:
            insightsArray.find(
              (insight) => insight.name === "total_interactions",
            )?.values[0].value || 0,
        },
        {
          title: "Average Watch Time (s)",
          value:
            (insightsArray.find(
              (insight) => insight.name === "ig_reels_avg_watch_time",
            )?.values[0].value || 0) / 1000,
        },
        {
          title: "Views",
          value:
            insightsArray.find((insight) => insight.name === "video_views")
              ?.values[0].value || 0,
        },
        {
          title: "Likes",
          value:
            insightsArray.find((insight) => insight.name === "likes")?.values[0]
              .value || 0,
        },
        {
          title: "Comments",
          value:
            insightsArray.find((insight) => insight.name === "comments")
              ?.values[0].value || 0,
        },
        {
          title: "Shares",
          value:
            insightsArray.find((insight) => insight.name === "shares")
              ?.values[0].value || 0,
        },
        {
          title: "Saves",
          value:
            insightsArray.find((insight) => insight.name === "saved")?.values[0]
              .value || 0,
        },
      ];
    } else if (item.media_type === "IMAGE") {
      return [
        {
          title: "Accounts Reached",
          value:
            insightsArray.find((insight) => insight.name === "reach")?.values[0]
              .value || 0,
        },
        {
          title: "Total Interactions",
          value:
            insightsArray.find(
              (insight) => insight.name === "total_interactions",
            )?.values[0].value || 0,
        },
        {
          title: "Impressions",
          value:
            insightsArray.find((insight) => insight.name === "impressions")
              ?.values[0].value || 0,
        },
        {
          title: "Likes",
          value:
            insightsArray.find((insight) => insight.name === "likes")?.values[0]
              .value || 0,
        },
        {
          title: "Comments",
          value:
            insightsArray.find((insight) => insight.name === "comments")
              ?.values[0].value || 0,
        },
        {
          title: "Shares",
          value:
            insightsArray.find((insight) => insight.name === "shares")
              ?.values[0].value || 0,
        },
        {
          title: "Saves",
          value:
            insightsArray.find((insight) => insight.name === "saved")?.values[0]
              .value || 0,
        },
      ];
    } else if (item.media_type === "STORY") {
      return [
        {
          title: "Accounts Reached",
          value:
            insightsArray.find((insight) => insight.name === "reach")?.values[0]
              .value || 0,
        },
        {
          title: "Total Interactions",
          value:
            insightsArray.find(
              (insight) => insight.name === "total_interactions",
            )?.values[0].value || 0,
        },
        {
          title: "Impressions",
          value:
            insightsArray.find((insight) => insight.name === "impressions")
              ?.values[0].value || 0,
        },
        {
          title: "Likes",
          value:
            insightsArray.find((insight) => insight.name === "likes")?.values[0]
              .value || 0,
        },
        {
          title: "Replies",
          value:
            insightsArray.find((insight) => insight.name === "replies")
              ?.values[0].value || 0,
        },
        {
          title: "Shares",
          value:
            insightsArray.find((insight) => insight.name === "shares")
              ?.values[0].value || 0,
        },
      ];
    }
    return [];
  };
  return (
    <Container className="container-root">
      <div className="button-group-container">
        <FormControl className="sort-by-selector">
          <Select
            value={selectedSort}
            onChange={(e) => setSelectedSort(e.target.value)}
            displayEmpty
          >
            <MenuItem value="" disabled>
              Sort By
            </MenuItem>
            <MenuItem value="comments">Comments</MenuItem>
            <MenuItem value="impressions">Impressions</MenuItem>
            <MenuItem value="latest">Latest</MenuItem>
            <MenuItem value="likes">Likes</MenuItem>
            <MenuItem value="quality-score">Quality Score</MenuItem>
            <MenuItem value="reach">Reach</MenuItem>
          </Select>
        </FormControl>
        <div className="button-groups">
          <ButtonGroup variant="outlined" style={{ marginRight: "-400px" }}>
            <Button
              className={selectedMediaType === "All" ? "selected" : ""}
              onClick={() => setSelectedMediaType("All")}
            >
              All
            </Button>
            <Button
              className={selectedMediaType === "Reel" ? "selected" : ""}
              onClick={() => setSelectedMediaType("Reel")}
            >
              Reel
            </Button>
            <Button
              className={selectedMediaType === "Post" ? "selected" : ""}
              onClick={() => setSelectedMediaType("Post")}
            >
              Post
            </Button>
            <Button
              className={selectedMediaType === "Stories" ? "selected" : ""}
              onClick={() => setSelectedMediaType("Stories")}
            >
              Stories
            </Button>
          </ButtonGroup>
        </div>
      </div>

      <Typography variant="h3" gutterBottom align="left">
        Dashboard
      </Typography>
      {filteredData.map((item) => {
        const insightsArray =
          item.insights && Array.isArray(item.insights.data)
            ? item.insights.data
            : [];
        return (
          <div
            key={item.id}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              marginBottom: "20px",
            }}
          >
            <Card
              className="media-card"
              sx={{
                backgroundColor: "transparent",
                boxShadow: "none",
                border: "none",
              }}
            >
              <div className="device-container">
                <DeviceFrameset
                  device="iPhone X"
                  color="black"
                  width="350px"
                  height="650px"
                >
                  <div className="instagram-post">
                    <div className="post-header">
                      <img
                        src={profilePicture || defaultImage}
                        alt="profile"
                        className="profile-pic"
                      />
                      <div className="post-user-info">
                        <span className="username">SociAloha</span>
                        <span className="location">{item.location}</span>
                      </div>
                    </div>
                    <div className="post-image">
                      <img
                        src={item.media_url || item.thumbnail_url}
                        alt="Post"
                      />
                    </div>
                    <div className="post-footer">
                      <div className="post-interactions">
                        <span>
                          ❤️{" "}
                          {insightsArray.find(
                            (insight) => insight.name === "likes",
                          )?.values[0].value || 0}
                        </span>
                        <span>
                          💬{" "}
                          {insightsArray.find(
                            (insight) => insight.name === "comments",
                          )?.values[0].value || 0}
                        </span>
                        <span>📤</span>
                      </div>
                      <div className="post-caption">
                        <span className="bold-text">SociAloha</span>{" "}
                        {item.caption}
                      </div>
                      <div className="post-hashtags">
                        <span>{item.hashtags}</span>
                      </div>
                      <div className="post-comments">
                        <span>
                          View all{" "}
                          {insightsArray.find(
                            (insight) => insight.name === "comments",
                          )?.values[0].value || 0}{" "}
                          comments
                        </span>
                      </div>
                    </div>
                  </div>
                </DeviceFrameset>
              </div>
            </Card>
            <GeneralStatistics
              statistics={getStatisticsForMedia(item, insightsArray)}
            />
          </div>
        );
      })}
    </Container>
  );
};

export default Dashboard;
