import React, { useState, useEffect, useCallback } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "../Scheduler/scheduler.scss";
import axios from "axios";
import { useAuth } from "../../contexts/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-modal";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { debounce } from "lodash";
import EditPostModal from "./editpostmodal";

const localizer = momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(Calendar);
const backendUrl = "https://socialoha-server-0b454a3e2f86.herokuapp.com";
// const backendUrl = 'http://localhost:8080'

Modal.setAppElement("#root");

const MyCalendar = () => {
  const [view, setView] = useState("month");
  const [eventsData, setEventsData] = useState([]);
  const { currentUser } = useAuth();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [hoveredEvent, setHoveredEvent] = useState(null);
  const [mousePosition, setMousePosition] = useState({ x: -1, y: -1 });
  const [isHovering, setIsHovering] = useState(false);
  const [campaigns, setCampaigns] = useState([]);

  const fetchCampaigns = useCallback(async (userId) => {
    if (userId) {
      try {
        const campaignResponse = await axios.get(
          `${backendUrl}/queries/data_campaigns/${userId}`
        );
        setCampaigns(campaignResponse.data);
      } catch (error) {
        console.error("Error fetching campaigns:", error);
      }
    }
  }, []);

  const debouncedHandleMouseEnter = debounce((event, e) => {
    if (!isHovering) {
      setIsHovering(true);
      setHoveredEvent(event);
      setMousePosition({ x: e.clientX, y: e.clientY });
    }
  }, 100);

  const handleMouseLeave = () => {
    setIsHovering(false);
    setHoveredEvent(null);
  };

  useEffect(() => {
    const hoverCheckInterval = setInterval(() => {
      if (!isHovering) {
        setHoveredEvent(null);
      }
    }, 100);

    return () => clearInterval(hoverCheckInterval);
  }, [isHovering, hoveredEvent]);

  // This useEffect runs only once when the component mounts
  useEffect(() => {
    const fetchPosts = async () => {
      if (currentUser && currentUser.email) {
        try {
          const userResponse = await axios.get(`${backendUrl}/queries/data_user`);
          const users = userResponse.data;
          const user = users.find((item) => item.email === currentUser.email);

          if (user) {
            const userId = user.user_id;

            // Fetch campaigns first
            await fetchCampaigns(userId);

            // Fetch posts
            const postsResponse = await axios.get(`${backendUrl}/queries/scheduled_posts/${userId}`);
            const posts = postsResponse.data.map((post) => ({
              ...post,
              type: "post",
            }));

            // Fetch reels
            const reelsResponse = await axios.get(`${backendUrl}/queries/scheduled_reel_posts/${userId}`);
            const reels = reelsResponse.data.map((reel) => ({
              ...reel,
              type: "reel",
            }));

            // Fetch stories
            const storiesResponse = await axios.get(`${backendUrl}/queries/scheduled_stories/${userId}`);
            const stories = storiesResponse.data.map((story) => ({
              ...story,
              type: "story",
            }));

            const allEvents = [...posts, ...reels, ...stories];
            const formattedEvents = allEvents.map((event) => {
              const startDateTime = moment(event.scheduled_at)
                .set({
                  hour: moment(event.time, "HH:mm:ss").hours(),
                  minute: moment(event.time, "HH:mm:ss").minutes(),
                  second: moment(event.time, "HH:mm:ss").seconds(),
                })
                .toDate();

              return {
                id: event.post_id || event.reel_id || event.story_id,
                title: event.caption || event.description || "No Title",
                start: startDateTime,
                end: startDateTime,
                allDay: false,
                type: event.type,
                thumbnail: event.file_url,
                campaign: event.campaign_id, //getCampaignNameById(event.campaign_id),
                time: event.time,
                hashtags: event.hashtags || "",
                location: event.location_id || "",
                tags: event.tags || "",
                user_id: userId
              };
            });

            setEventsData(formattedEvents);
          } else {
            console.error("User not found");
          }
        } catch (error) {
          console.error("Error fetching posts:", error);
        }
      }
    };

    fetchPosts();
  }, [currentUser, fetchCampaigns]);

  const getCampaignNameById = (campaignId) => {
    const campaign = campaigns.find((c) => c.campaign_id === campaignId);
    return campaign ? campaign.name : "No Campaign";
  };

  const openDeleteModal = (event) => {
    setSelectedEvent(event);
    setIsDeleteModalOpen(true);
  };

  const openEditModal = (event) => {
    setSelectedEvent(event);
    setIsEditModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedEvent(null);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedEvent(null);
  };

  const handleEditSave = (updatedEvent) => {
    const updatedEvents = eventsData.map((event) =>
      event.id === updatedEvent.id ? updatedEvent : event
    );
    setEventsData(updatedEvents);
    closeEditModal();
  };

  const updateEvent = async (event, start) => {
    const updatedEvent = {
      date: moment(start).format("YYYY-MM-DD"),
      time: moment(start).format("HH:mm:ss"),
    };

    let url;
    if (event.type === "post") {
      url = `${backendUrl}/queries/update_post/${event.id}`;
    } else if (event.type === "reel") {
      url = `${backendUrl}/queries/update_reel/${event.id}`;
    } else if (event.type === "story") {
      url = `${backendUrl}/queries/update_story/${event.id}`;
    }

    try {
      await axios.put(url, updatedEvent);
    } catch (error) {
      console.error(`Error updating ${event.type}:`, error);
    }
  };

  const handleDelete = async () => {
    closeDeleteModal();
    let url;
    if (selectedEvent.type === "post") {
      url = `${backendUrl}/queries/delete_post/${selectedEvent.id}`;
    } else if (selectedEvent.type === "reel") {
      url = `${backendUrl}/queries/delete_reel/${selectedEvent.id}`;
    } else if (selectedEvent.type === "story") {
      url = `${backendUrl}/queries/delete_story/${selectedEvent.id}`;
    }

    try {
      await axios.delete(url);
      setEventsData(eventsData.filter((event) => event.id !== selectedEvent.id));
    } catch (error) {
      console.error("Error deleting event:", error);
    }
  };

  const moveEvent = ({ event, start }) => {
    const updatedEvents = eventsData.map((existingEvent) => {
      if (existingEvent.id === event.id) {
        updateEvent(event, start);
        return { ...existingEvent, start, end: start };
      }
      return existingEvent;
    });
    setEventsData(updatedEvents);
  };

  const resizeEvent = ({ event, start }) => {
    const updatedEvents = eventsData.map((existingEvent) => {
      if (existingEvent.id === event.id) {
        updateEvent(event, start);
        return { ...existingEvent, start, end: start };
      }
      return existingEvent;
    });
    setEventsData(updatedEvents);
  };

  const customEvent = ({ event }) => {
    // Determine the class based on the event type
    const eventClass = `event-item ${event.type}`;
  
    return (
      <div
        className={eventClass}
        onMouseEnter={(e) => debouncedHandleMouseEnter(event, e)}
        onMouseLeave={handleMouseLeave}
        onClick={(e) => {
          openEditModal(event);
          handleMouseLeave();
          e.stopPropagation();
        }}
      >
        <span>{event.title}</span>
        <div
          className="delete-container"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <FontAwesomeIcon
            icon={faTrashAlt}
            className="delete-icon"
            onClick={(e) => {
              e.stopPropagation();
              openDeleteModal(event);
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="calendar-container">
      <div className="calendar-legend">
        <span className="legend-item post">Posts</span>
        <span className="legend-item reel">Reels</span>
        <span className="legend-item story">Stories</span>
      </div>
      <DragAndDropCalendar
        localizer={localizer}
        events={eventsData}
        startAccessor="start"
        endAccessor="end"
        defaultView={view}
        onView={(view) => setView(view)}
        onEventDrop={moveEvent}
        onEventResize={resizeEvent}
        resizable
        views={["month", "week", "day"]}
        components={{
          event: customEvent,
          toolbar: (props) => (
            <div className="rbc-toolbar">
              <span className="rbc-btn-group">
                <button type="button" onClick={() => props.onNavigate("TODAY")}>
                  Today
                </button>
                <button type="button" onClick={() => props.onNavigate("PREV")}>
                  Back
                </button>
                <button type="button" onClick={() => props.onNavigate("NEXT")}>
                  Next
                </button>
              </span>
              <span className="rbc-toolbar-label">{props.label}</span>
              <span className="rbc-btn-group">
                {props.views.map((viewName) => (
                  <button
                    key={viewName}
                    type="button"
                    onClick={() => props.onView(viewName)}
                    className={props.view === viewName ? "rbc-active" : ""}
                  >
                    {viewName.charAt(0).toUpperCase() + viewName.slice(1)}
                  </button>
                ))}
              </span>
            </div>
          ),
        }}
      />
      {hoveredEvent && (
        <div
          className="event-preview"
          style={{
            top: `${mousePosition.y - 115}px`,
            left: `${mousePosition.x - 215}px`,
            position: "absolute",
          }}
        >
          {hoveredEvent.thumbnail.endsWith(".mp4") || hoveredEvent.thumbnail.endsWith(".mov")? (
            <video
              className="event-thumbnail"
              src={hoveredEvent.thumbnail}
              width="200"
              height="auto"
              autoPlay
              loop
              playsInline
              controls
            >
              Your browser does not support the video tag.
            </video>
          ) : (
            <img
              src={hoveredEvent.thumbnail}
              alt="Thumbnail"
              className="event-thumbnail"
            />
          )}

          <div className="event-preview-details">
            <strong>{hoveredEvent.type.toUpperCase()}</strong>
            <p>{hoveredEvent.title}</p>
            <span className="event-preview-time">
              {moment(hoveredEvent.start).format("MMMM Do, h:mm A")}
            </span>
          </div>
        </div>
      )}
      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={closeDeleteModal}
        contentLabel="Delete Confirmation"
        className="delete-modal-content"
        overlayClassName="delete-modal-overlay"
      >
        <h2>Are you sure you want to delete this post?</h2>
        <p>{selectedEvent ? `Post: ${selectedEvent.title}` : ""}</p>
        <div className="delete-modal-buttons">
          <button onClick={handleDelete} className="btn-confirm-delete">
            Delete
          </button>
          <button onClick={closeDeleteModal} className="btn-cancel-delete">
            Cancel
          </button>
        </div>
      </Modal>
      <EditPostModal
        isOpen={isEditModalOpen}
        onClose={closeEditModal}
        postDetails={selectedEvent}
        campaigns={campaigns}
        onSave={handleEditSave}
      />
    </div>
  );
};

export default MyCalendar;
