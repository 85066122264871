import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { createUser, loginGoogleUser } from "../../services/firebaseService";
import { useNavigate } from "react-router-dom";
import GoogleIcon from "../../assets/google-icon.svg";

export default function Signup() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isEmailEntered, setIsEmailEntered] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }
    try {
      const userId = await createUser({
        name: name,
        email: email,
        password: password,
      });
      console.log(userId);
      navigate("/home");
    } catch (error) {
      alert("Failed to create user: " + error.message);
    }
  };

  const handleGoogleSubmit = async () => {
    await loginGoogleUser();
    navigate("/home");
  };

  return (
    <div className="bg-cream h-screen">
      <div className="h-[4%]" />
      <div className="h-[96%] flex">
        <motion.div
          className="w-1/2 bg-teal flex items-center justify-center relative"
          initial={{ x: "-100%" }}
          animate={{ x: 0 }}
          transition={{ duration: 1 }}
        >
          <h1 className="absolute top-4 left-8 text-white text-4xl font-dm-serif">
            SociAloha
          </h1>
          <p className="absolute bottom-8 left-8 text-gray-100 text-xl font-dm-sans">
            Your Pocket AI Content Creator and Social Media Manager
          </p>
        </motion.div>
        <div className="w-1/2 flex flex-col items-center justify-center">
          <a
            href="/login"
            className="absolute right-8 top-8 hover:bg-accent hover:text-accent-foreground"
          >
            Login
          </a>

          <div className="flex flex-col space-y-2 text-center">
            <h1 className="text-3xl font-semibold font-eb-garamond">
              Create an account
            </h1>
            <p className="text-sm text-muted-foreground text-grey-300 font-dm-sans">
              {!isEmailEntered
                ? "Enter your name and email below to create your account"
                : "Enter and confirm your password below to create your account"}
            </p>
          </div>

          <div className="w-1/2 flex flex-col mt-8">
            {!isEmailEntered && (
              <input
                placeholder="Full Name"
                className="p-2 border border-gray-300 rounded-lg placeholder:text-gray-500"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            )}

            {!isEmailEntered && (
              <input
                type="email"
                placeholder="name@example.com"
                className="mt-1 p-2 border border-gray-300 rounded-lg placeholder:text-gray-500"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            )}

            {isEmailEntered && (
              <input
                type="password"
                placeholder="Password"
                className="p-2 border border-gray-300 rounded-lg placeholder:text-gray-500"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            )}

            {isEmailEntered && (
              <input
                type="password"
                placeholder="Confirm Password"
                className="mt-1 p-2 border border-gray-300 rounded-lg placeholder:text-gray-500"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            )}

            {!isEmailEntered && (
              <button
                className="mt-3 p-2 bg-teal text-white rounded-lg hover:bg-light-teal transition-colors duration-500 ease-in-out font-dm-sans"
                onClick={() => setIsEmailEntered(true)}
              >
                Sign Up with Email
              </button>
            )}

            {isEmailEntered && (
              <button
                className="mt-3 p-2 bg-teal text-white rounded-lg hover:bg-light-teal transition-colors duration-500 ease-in-out font-dm-sans"
                onClick={handleSubmit}
              >
                Create Account
              </button>
            )}

            <div className="mt-6 relative">
              <div className="absolute inset-0 flex items-center">
                <span className="w-full border-t" />
              </div>
              <div className="relative flex justify-center text-xs uppercase">
                <span className="bg-cream px-2 text-muted-foreground font-bricolage-grotesque">
                  Or continue with
                </span>
              </div>
            </div>

            <div className="mt-6 flex justify-center font-dm-sans">
              <button
                onClick={handleGoogleSubmit}
                className="flex items-center border border-gray-300 text-black py-2 px-4 rounded-lg bg-white hover:bg-gray-300 hover:text-black transition-colors duration-300"
              >
                <img className="mr-2 h-4 w-4" src={GoogleIcon} alt="Google" />
                <p>Google</p>
              </button>
            </div>
          </div>

          <div className="mt-4 w-[330px] text-gray-500">
            <p className="px-8 text-center text-sm text-muted-foreground font-dm-sans">
              By clicking continue, you agree to our{" "}
              <a
                href="/terms"
                className="underline underline-offset-4 hover:text-primary"
              >
                Terms of Service
              </a>{" "}
              and{" "}
              <a
                href="/privacy"
                className="underline underline-offset-4 hover:text-primary"
              >
                Privacy Policy
              </a>
              .
            </p>
          </div>

          <div className="mt-8">
            <p className="text-sm text-muted-foreground font-dm-sans">
              Already have an account?{" "}
              <a
                href="/login"
                className="text-teal text-accent hover:text-accent-foreground"
              >
                Login
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
