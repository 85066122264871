// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth"; // Import the auth module

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAubDgG_Rd1ocSfzGTLV06HU3AhnWkW1Ko",
  authDomain: "socialoha.firebaseapp.com",
  projectId: "socialoha",
  storageBucket: "socialoha.appspot.com",
  messagingSenderId: "500001058086",
  appId: "1:500001058086:web:c29e704315b48f6ba765e7",
  measurementId: "G-FZTLVXF5D0",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app); // Initialize Firestore
const auth = getAuth(app); // Initialize Firebase Authentication
// const analytics = getAnalytics(app); // Initialize Analytics if you need it#

export { db, auth }; // Export both Firestore and Auth
