import React from "react";
import "./feedbacksurvey.scss";
import logo from "../../assets/socialoha_logo.png";

const FeedbackSurvey = () => {
  return (
    <div className="survey-container">
      <header>
        <nav>
          <div className="logo">
            <img src={logo} alt="Logo" className="logo" />
            <h1>SociAloha</h1>
          </div>
          <ul className="nav-links">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/privacy-policy">Privacy Policy</a>
            </li>
            <li>
              <a href="/feedback-survey">Survey</a>
            </li>
            <li>
              <a href="/terms-of-service">Terms of Service</a>
            </li>
            <li>
              <a href="/contact">Contact Us</a>
            </li>
            <li>
              <a href="/SignUp">Try it Out</a>
            </li>
          </ul>
        </nav>
      </header>
      <main>
        <section id="survey">
          <h2>Survey</h2>
          <form action="/submit-survey" method="post">
            <label htmlFor="name">Name:</label>
            <input type="text" id="name" name="name" required />

            <label htmlFor="email">Email:</label>
            <input type="email" id="email" name="email" required />

            <label htmlFor="feedback">Feedback:</label>
            <textarea
              id="feedback"
              name="feedback"
              rows="4"
              required
            ></textarea>

            <button type="submit">Submit</button>
          </form>
        </section>
      </main>
      <footer>
        <p>&copy; 2024 SociAloha. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default FeedbackSurvey;
