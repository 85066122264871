import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  TextField,
  Paper,
  Grid,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "./ideate.scss";
import { useAuth } from "../../contexts/AuthContext";
import axios from "axios";

const backendUrl = "https://socialoha-server-0b454a3e2f86.herokuapp.com";

const InfinityLoader = () => (
  <div className="infinity-loader">
    <div className="infinity"></div>
  </div>
);

const Loader = () => (
  <div className="loader-container">
    <div className="loader"></div>
  </div>
);

const Ideate = ({ formData, setFormData }) => {
  const { currentUser } = useAuth();
  const [recommendations, setRecommendations] = useState(["", "", ""]);
  const [subIdeas, setSubIdeas] = useState([]);
  const [selectedMainIdea, setSelectedMainIdea] = useState("");
  const [selectedSubIdea, setSelectedSubIdea] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isSubLoading, setIsSubLoading] = useState(false);
  const [loadingIndices, setLoadingIndices] = useState([]);
  const [subLoadingIndices, setSubLoadingIndices] = useState([]);
  const [isFormDataLoaded, setIsFormDataLoaded] = useState(false);
  const [hasFetched, setHasFetched] = useState(false); // State to track if recommendations have been fetched

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser && currentUser.email) {
        try {
          const userResponse = await axios.get(
            `${backendUrl}/queries/data_user`,
          );
          const users = userResponse.data;
          const user = users.find((item) => item.email === currentUser.email);

          if (user) {
            const userId = user.user_id;
            const businessResponse = await axios.get(
              `${backendUrl}/queries/data_business_info`,
            );
            const businessData = businessResponse.data;
            const userData = businessData.find(
              (item) => item.user_id === userId,
            );

            if (userData) {
              const productOptions = userData.list_products.split(",");
              setFormData((prev) => ({
                ...prev,
                business_name: userData.business_name || "",
                industry: userData.industry || "",
                audience: userData.target_audience || "",
                productOptions,
              }));
              setIsFormDataLoaded(true);
            }
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchUserData();
  }, [currentUser, setFormData]);

  useEffect(() => {
    const fetchRecommendations = async () => {
      if (
        !hasFetched &&
        isFormDataLoaded &&
        formData.business_name &&
        formData.industry &&
        formData.audience
      ) {
        setHasFetched(true); // Ensure only one fetch happens
        try {
          const response = await axios.post(
            `${backendUrl}/api/recommendations`,
            formData,
          );
          console.log("recommendations", response.data);
          setRecommendations(response.data);
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching recommendations:", error);
          setIsLoading(false);
        }
      }
    };

    if (!hasFetched) {
      fetchRecommendations();
    }
  }, [isFormDataLoaded, formData, hasFetched]);

  const fetchNewRecommendation = async (index) => {
    setLoadingIndices((prev) => [...prev, index]);
    try {
      const response = await axios.post(
        `${backendUrl}/api/new_recommendations`,
        {
          ...formData,
          previous_ideas: recommendations,
        },
      );
      const newRecommendation = response.data.recommendations[0];
      console.log("new recommendations", newRecommendation);
      setRecommendations((prevRecommendations) => {
        const updatedRecommendations = [...prevRecommendations];
        updatedRecommendations[index] = newRecommendation;
        return updatedRecommendations;
      });
    } catch (error) {
      console.error("Error fetching new recommendation:", error);
    } finally {
      setLoadingIndices((prev) => prev.filter((i) => i !== index));
    }
  };

  const fetchSubIdeas = async (mainIdea) => {
    setIsSubLoading(true);
    setSelectedMainIdea(mainIdea);
    setFormData((prev) => ({
      ...prev,
      mainIdea,
    }));
    setSelectedSubIdea("");
    try {
      const response = await axios.post(`${backendUrl}/api/subideas`, {
        ...formData,
        main_idea: mainIdea,
      });
      setSubIdeas(response.data.sub_ideas);
    } catch (error) {
      console.error("Error fetching sub-ideas:", error);
    } finally {
      setIsSubLoading(false);
    }
  };

  const fetchNewSubIdea = async (index) => {
    setSubLoadingIndices((prev) => [...prev, index]);
    try {
      const response = await axios.post(`${backendUrl}/api/new_subidea`, {
        ...formData,
        main_idea: selectedMainIdea,
        previous_sub_ideas: subIdeas,
      });
      const newSubIdea = response.data.new_sub_idea;

      setSubIdeas((prevSubIdeas) => {
        const updatedSubIdeas = [...prevSubIdeas];
        updatedSubIdeas[index] = newSubIdea;
        return updatedSubIdeas;
      });
    } catch (error) {
      console.error("Error fetching new sub-idea:", error);
    } finally {
      setSubLoadingIndices((prev) => prev.filter((i) => i !== index));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubIdeaClick = (subIdea) => {
    setSelectedSubIdea(subIdea);
    setFormData((prev) => ({
      ...prev,
      subIdea,
    }));
  };

  return (
    <Box className="aicontent-container px-8">
      <Typography
        variant="h4"
        gutterBottom
        style={{ fontWeight: "bold", marginBottom: "20px", marginTop: "20px" }}
      >
        Today's Content Recommendations
      </Typography>
      <Grid container className="content-recommendations">
        {isLoading
          ? [0, 1, 2].map((index) => (
              <Grid
                item
                xs={12}
                md={4}
                key={index}
                className="recommendation-box"
              >
                <InfinityLoader />
              </Grid>
            ))
          : recommendations.map((recommendation, index) => (
              <Grid
                item
                xs={12}
                md={4}
                key={index}
                className="recommendation-box px-8"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  height="100%"
                  position="relative"
                  sx={{
                    backgroundColor:
                      selectedMainIdea === recommendation
                        ? "rgba(0, 0, 0, 0.05)"
                        : "transparent",
                    cursor: "pointer",
                  }}
                >
                  {loadingIndices.includes(index) ? (
                    <Loader />
                  ) : (
                    <div className="flex items-center justify-between w-full min-h-[90px]">
                      <RefreshIcon
                        onClick={() => fetchNewRecommendation(index)}
                        style={{ cursor: "pointer" }}
                        className="mr-2"
                      />
                      <Typography
                        variant="h6"
                        className="recommendation-text"
                        onClick={() => fetchSubIdeas(recommendation)}
                      >
                        {recommendation}
                      </Typography>
                      <KeyboardArrowDownIcon
                        onClick={() => fetchSubIdeas(recommendation)}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  )}
                </Box>
              </Grid>
            ))}
      </Grid>

      <Typography
        variant="h4"
        gutterBottom
        style={{ fontWeight: "bold", marginBottom: "20px", marginTop: "20px" }}
      >
        Select one Reel to Create
      </Typography>
      <Grid container spacing={5} sx={{ width: "100%" }}>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ padding: 4, width: "100%" }}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              sx={{ marginBottom: 5 }}
            >
              <Box display="flex" alignItems="center">
                <p className="text-2xl font-dm-sans">
                  {selectedMainIdea || "Select a main idea"}
                </p>
              </Box>
            </Box>

            {isSubLoading ? (
              <Loader />
            ) : (
              subIdeas.map((subIdea, index) => (
                <Box
                  key={index}
                  className={`sub-idea-container ${selectedSubIdea === subIdea ? "selected" : ""}`}
                  onClick={() => handleSubIdeaClick(subIdea)}
                >
                  {subLoadingIndices.includes(index) ? (
                    <Loader />
                  ) : (
                    <>
                      <div className="sub-idea-text-container">
                        <Typography variant="h7" className="sub-idea-text">
                          {subIdea}
                        </Typography>
                      </div>
                      <RefreshIcon
                        onClick={(e) => {
                          e.stopPropagation();
                          fetchNewSubIdea(index);
                        }}
                        style={{
                          cursor: "pointer",
                        }}
                        className="mr-2"
                      />
                    </>
                  )}
                </Box>
              ))
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          {selectedSubIdea && (
            <Paper elevation={2} sx={{ padding: 2, width: "100%" }}>
              <Box className="reel-settings">
                <Box className="input-group" sx={{ marginBottom: 2 }}>
                  <p className="font-dm-sans font-bold">Product</p>
                  <Select
                    fullWidth
                    name="product"
                    value={formData.product}
                    onChange={handleInputChange}
                    sx={{ height: 40, fontSize: 14 }}
                    className="outline outline-1 outline-light-teal"
                  >
                    {formData.productOptions.map((product, index) => (
                      <MenuItem key={index} value={product.trim()}>
                        {product.trim()}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box className="input-group" sx={{ marginBottom: 2 }}>
                  <p className="font-dm-sans font-bold">Duration</p>
                  <Select
                    fullWidth
                    name="duration"
                    value={formData.duration}
                    onChange={handleInputChange}
                    sx={{ height: 40, fontSize: 14 }}
                    className="outline outline-1 outline-light-teal"
                  >
                    <MenuItem value="2-15">2-15 seconds</MenuItem>
                    <MenuItem value="15-30">15-30 seconds</MenuItem>
                    <MenuItem value="30-60">30-60 seconds</MenuItem>
                    <MenuItem value="60-90">60-90 seconds</MenuItem>
                  </Select>
                </Box>
                <Box className="input-group" sx={{ marginBottom: 2 }}>
                  <p className="font-dm-sans font-bold">Audience</p>
                  <TextField
                    fullWidth
                    multiline
                    rows={0.5}
                    name="audience"
                    value={formData.audience}
                    onChange={handleInputChange}
                    sx={{
                      fontSize: 14,
                      "& .MuiOutlinedInput-root": {
                        padding: 0,
                        "& fieldset": { border: "none" },
                      },
                    }}
                    className="outline outline-1 outline-light-teal"
                  />
                </Box>
                <Box className="input-group" sx={{ marginBottom: 2 }}>
                  <p className="font-dm-sans font-bold">Content</p>
                  <TextField
                    fullWidth
                    multiline
                    rows={2}
                    name="more"
                    value={formData.more}
                    onChange={handleInputChange}
                    sx={{
                      fontSize: 14,
                      "& .MuiOutlinedInput-root": {
                        padding: 0,
                        "& fieldset": { border: "none" },
                      },
                    }}
                    className="outline outline-1 outline-light-teal"
                  />
                </Box>
              </Box>
            </Paper>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Ideate;
