import React, { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import "../../App.css";
import "./newlandingpage.scss";
import logo from "../../assets/new-logo.svg";
import iPhoneShell from "../../assets/iphone-shell.svg";
import Calendar from "../../assets/calendar.png";
import Ideate from "../../assets/ideate.png";
import Analytics from "../../assets/analytics.png";
import Reel from "../../assets/reel.mp4";
import WhiteArrow from "../../assets/white-arrow.png";
import BlackArrow from "../../assets/black-arrow.png";
import BackgroundLines from "../../assets/background-lines.png";

export default function NewLandingPage() {
  const [word, setWord] = useState("Builds");
  const words = ["Builds", "Scripts", "Schedules", "Analyzes"];

  useEffect(() => {
    let index = 0;
    const intervalId = setInterval(() => {
      index = index + 1 === words.length ? 0 : index + 1;
      setWord(words[index]);
    }, 1500);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="bg-white h-screen">
      <div className="bg-[#f7f6f5] shadow-md flex items-center justify-between w-full px-2 md:px-8 py-4">
        <div className="flex items-center -ml-6 md:ml-0">
          <img src={logo} alt="Logo" className="w-10 h-10" />
          <h1 className="text-grey text-3xl md:text-4xl font-dm-serif">
            Soci<span className="text-teal">Al</span>oha
          </h1>
        </div>
        <div className="space-x-2 flex items-center">
          {/* <button className="bg-grey font-dm-sans text-white px-4 py-2 rounded">
                        Login
                    </button> */}
          <a
            href="https://tally.so/r/w2L5RV"
            target="_blank"
            className="border-animation bg-teal hover:bg-light-teal transition-colors duration-500 ease-in-out font-dm-sans text-black px-4 py-2 rounded flex items-center space-x-2"
          >
            <p className="text-sm md:text-md">Join the Waitlist</p>
            <img src={BlackArrow} alt="Black Arrow" className="w-4 h-4" />
          </a>
        </div>
      </div>

      <div
        className="moving-background"
        style={{
          backgroundImage: `url(${BackgroundLines})`,
        }}
      >
        <div className="w-full md:w-4/5 mx-auto pt-16 flex">
          <div className="w-full md:w-4/5 flex items-center">
            <div className="text-center md:text-left px-4 md:px-0">
              <h2 className="text-6xl font-bold font-dm-serif tracking-wide">
                AI that <span className="text-teal">{word}</span> your Instagram
              </h2>

              <p className="text-light-grey font-dm-sans text-xl mt-2 md:mt-4">
                Your Pocket AI Content Creator and Social Media Manager!
              </p>

              <a
                href="https://tally.so/r/w2L5RV"
                target="_blank"
                className="border-animation mt-8 md:mt-16 bg-teal hover:bg-light-teal transition-colors duration-500 font-dm-sans text-white px-8 py-4 rounded inline-flex items-center space-x-2"
              >
                {/* <span className="absolute animate-border-move"></span> */}
                <p className="text-xl">Join the Waitlist</p>
                <img src={WhiteArrow} alt="White Arrow" className="w-6 h-6" />
              </a>
            </div>
          </div>

          {!isMobile && (
            <div className="w-2/5 flex justify-center items-center relative">
              <img
                src={iPhoneShell}
                alt="iPhone Shell"
                className="w-3/4 h-auto"
              />
              <video
                src={Reel}
                autoPlay
                loop
                muted
                className="absolute top-0 left-0 w-[65%] h-[100%] mt-[0%] ml-[17.5%]"
              />
            </div>
          )}
        </div>
      </div>

      {/* <div className="mt-28 flex justify-center">
                <h3 className="text-4xl font-sf-pro">How It Works</h3>
            </div> */}

      <div className="overflow-hidden w-full mt-28">
        <div
          className="flex animate-scroll-left"
          style={{ height: "auto", minHeight: "calc(1.5em * 7)" }}
        >
          {Array.from({ length: 10 }).map((_, index) => (
            <p
              key={index}
              className="font-dm-sans tracking-tighter text-4xl md:text-5xl text-dark-teal whitespace-nowrap mx-auto"
            >
              build. create. grow. repeat.&nbsp;
            </p>
          ))}
        </div>
      </div>

      <div className="w-[90%] bg-[#f7f6f5]/75 pt-8 rounded-xl mx-auto">
        <div className="mt-4 md:mt-8 pb-8 md:pb-0 w-[90%] mx-auto flex flex-col space-y-40">
          <div className="flex flex-col-reverse md:flex-row space-x-0 space-y4 md:space-y-0 md:space-x-8">
            <div className="w-full md:w-1/2 mt-4 md:mt-0">
              <h3 className="text-4xl md:text-6xl font-sf-pro text-teal text-center md:text-left">
                Ideate Content
              </h3>
              <p className="mt-4 md:mt-6 text-lg font-dm-sans">
                SociAloha’s AI comprehends every aspect of your business and it
                generates ready-to-record scripts and captions tailored to your
                needs!
              </p>
            </div>
            <div className="w-full md:w-1/2 flex justify-center items-center">
              <img src={Ideate} alt="Ideate" />
            </div>
          </div>

          <div className="flex flex-col-reverse md:flex-row space-x-0 space-y-4 md:space-y-0 md:space-x-8">
            <div className="w-full md:w-1/2 mt-4 md:mt-0">
              <h3 className="text-4xl md:text-6xl font-sf-pro text-teal text-center md:text-left">
                Schedule Content
              </h3>
              <p className="mt-4 md:mt-6 text-lg font-dm-sans">
                SociAloha enables you to schedule your content, whether newly
                created or pre-made, with category tags for easy tracking and
                optimal posting times!
              </p>
            </div>
            <div className="w-full md:w-1/2 flex justify-center items-center">
              <img src={Calendar} alt="Calendar" />
            </div>
          </div>

          <div className="flex flex-col-reverse md:flex-row space-x-0 md:space-x-8">
            <div className="w-full md:w-1/2 -mt-[7.5rem] md:mt-0">
              <h3 className="text-4xl md:text-6xl font-sf-pro text-teal text-center md:text-left">
                Improve Content
              </h3>
              <p className="mt-4 md:mt-6 text-lg font-dm-sans">
                Fed up with just seeing numbers? SociAloha provides a content
                quality score, clear categories, and AI recommendations to
                improve your content based on your analytics.
              </p>
            </div>
            <div className="w-full md:w-1/2 flex justify-center items-center">
              <img src={Analytics} alt="Analytics" />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#333333] text-white flex justify-center py-4 mt-16">
        <p>&copy; 2024 SociAloha. All rights reserved.</p>
      </div>
    </div>
  );
}
